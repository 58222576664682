<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
          <v-checkbox v-model="v2" label="V2"></v-checkbox>
        </v-col> -->
        <v-col cols="12" sm="12" md="3" lg="3">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>600.00000 PENJUALAN & PENDAPATAN</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>610.00000 PENJUALAN RP</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>611.00000 PENJUALAN RP</td>
              <td>{{ formatPrice(labaRugi.account611) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>612.00000 PENJUALAN DATA CENTER</td>
              <td>{{ formatPrice(labaRugi.account612) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>613.00000 PENJUALAN TELECOM</td>
              <td>{{ formatPrice(labaRugi.account613) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>614.00000 RETUR PENJUALAN</td>
              <td>{{ formatPrice(labaRugi.account614) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalSell) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>650.00000 PENDAPATAN</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>651.00000 PENDAPATAN INSTALASI</td>
              <td>{{ formatPrice(labaRugi.account651) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>652.00000 PENDAPATAN REPAIR</td>
              <td>{{ formatPrice(labaRugi.account652) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>653.00000 PENDAPATAN SURVEY</td>
              <td>{{ formatPrice(labaRugi.account653) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>654.00000 PENDAPATAN DELIVERY</td>
              <td>{{ formatPrice(labaRugi.account654) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>655.00000 PENDAPATAN COMMISSIONIN</td>
              <td>{{ formatPrice(labaRugi.account655) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>656.00000 PENDAPATAN MAINTANANCE</td>
              <td>{{ formatPrice(labaRugi.account656) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>657.00000 PENDAPATAN SEWA</td>
              <td>{{ formatPrice(labaRugi.account657) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalIncome) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalSellAndIncome) }}</td>
            </tr>
            <tr>
              <td>700.00000 COGS & COST OF SERVICES</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>710.00000 COST OF GOODS SOLD</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account710) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>720.00000 COST OF SERVICES</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account720) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalCost) }}</td>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td></td>
              <td>LABA KOTOR</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalLabaKotor) }}</td>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td>900.00000 BIAYA UMUM DAN ADMINISTRASI</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>901.00000 Biaya Gaji dan Tunjangan</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account901) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>902.00000 Biaya Tenaga Ahli</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account902) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>903.00000 Biaya Keperluan Kantor</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account903) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>904.00000 Biaya Alat Tulis Kantor</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account904) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>905.00000 Biaya Telekomunikasi</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account905) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>907.00000 Biaya Penyusutan</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account907) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>908.00000 Biaya Transportasi & Perjalanan Dinas</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account908) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>909.00000 Biaya Promosi Dan Marketing</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account909) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>910.00000 Biaya Pengembangan SDM</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account910) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>911.00000 Biaya Pos Dan Materai</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account901) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>912.00000 Biaya Listrik Kantor</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account911) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>914.00000 Biaya Sewa Kendaraan Dan Peralatan</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account912) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>915.00000 Biaya Buku Cek Dan Giro</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account914) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>916.00000 Biaya Administrasi Bank</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account915) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>917.00000 Biaya Asuransi Aset</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account917) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>918.00000 Biaya Pemeliharaan Aset</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account918) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>919.00000 Biaya Paja</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account919) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalBiaya) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>LABA USAHA</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalLabaUsaha) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>690.00000 PENDAPATAN LAIN-LAIN</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>691.00000 Keuntungan Selisih Kurs</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account691A) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>692.00000 Pendapatan Jasa Giro</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account692) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>693.00000 Pendapatan Bunga Non Bank</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account693) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>694.00000 Pendapatan Sewa</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account694) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>695.00000 Laba Penjualan Aktiva Tetap</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account695) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>699.99999 Pendapatan Lainnya</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account699) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalPendapatanLain) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>980.00000 BIAYA LAIN-LAIN</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>691.00000 Rugi Selisih Kurs</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account691B) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>980.00001 Biaya Bunga Bank</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account9801) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>980.00002 Biaya Denda</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account9802) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>980.00003 Biaya Bunga Pinjaman Lain</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account9803) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>980.00004 Biaya Pengelolahan Limbah</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account9804) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>980.99999 Biaya Lainnya</td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.account9809) }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalBiayaLain) }}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>LABA SEBELUM PAJAK</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatPrice(labaRugi.totalLabaSebelumPajak) }}</td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-laba-rugi",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        startDate: moment()
          .startOf("year")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("year")
          .format("yyyy-MM-DD"),
      },
      v2: true,
    };
  },
  computed: {
    ...mapState("report", ["labaRugi"]),
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      if (this.v2) {
        this.$store
          .dispatch("report/labaRugiV2", this.params)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store
          .dispatch("report/labaRugi", this.params)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      if (this.v2) {
        this.$store
          .dispatch("report/labaRugiExcelV2", this.params)
          .then(response => {
            fileDownload(response.data, `laba-rugi.xlsx`);
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store
          .dispatch("report/labaRugiExcel", this.params)
          .then(response => {
            fileDownload(response.data, `laba-rugi.xlsx`);
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
